<template>
  <v-dialog v-model="dialog" persistent max-width="600px">
    <v-card class="pb-5">
      <v-card-text>
        <div align="center" class="pt-2">
          <p>{{ $t("add_recruiter") }}</p>
        </div>

        <v-row>
          <v-col cols="12" md="12">
            <v-combobox
              v-model="recruters"
              :items="filteredRecruters"
              :label="$t('recruiters')"
              chips
              item-text="name"
              item-value="id"
              multiple
              dense
              outlined
              hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  small
                  @click="data.select"
                  @click:close="removeRecruter(data.item)"
                >
                  <v-avatar left size="24">
                    <v-img
                      :src="
                        data.item.photo
                          ? `${apiUrl}/images/user/${data.item.photo}`
                          : `/avatar.png`
                      "
                    ></v-img>
                  </v-avatar>
                  {{ data.item.name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-avatar size="24">
                    <img
                      :src="
                        data.item.photo
                          ? `${apiUrl}/images/user/${data.item.photo}`
                          : `/avatar.png`
                      "
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="data.item.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="data.item.office"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="selectedCandidate.subject"
              :label="$t('subject')"
              dense
              outlined
              hide-details
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="date"
              :label="$t('interview_date')"
              prepend-inner-icon="event"
              readonly
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="6">
            <v-text-field
              v-model="time"
              :label="$t('interview_time')"
              prepend-inner-icon="access_time"
              readonly
              dense
              outlined
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="selectedCandidate.address"
              :label="$t('phisic_adress')"
              dense
              prepend-inner-icon="place"
              outlined
              hide-details
              readonly
            ></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-text-field
              v-model="selectedCandidate.telephone"
              :label="$t('Institution_telephone')"
              dense
              prepend-inner-icon="mdi-phone"
              outlined
              hide-details
              readonly
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="px-5">
        <v-row class="d-flex flex-row justify-end">
          <v-btn text @click="cancel()">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn color="primary" :loading="isLoading" @click="addRecruter()">
            {{ $t("Add") }}
          </v-btn>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import { ADD_RECRUTER_TO_INTERVIEW_MUTATION } from "../graphql/Mutation.gql";
import { GET_USERS_ENTITY_QUERY } from "../graphql/Query.gql";
export default {
  name: "AddRecruterDialog",

  data: () => ({
    apiUrl: API_URL,
    dialog: false,
    existingRecruters: [],
    existingObservers: [],
    recruters: [],
    users: [],
    selectedCandidate: {},
    date: "",
    time: "",
    interviewId: null,
    interviews: [],
    isLoading: false,
  }),

  apollo: {
    users: {
      query: GET_USERS_ENTITY_QUERY,
    },
  },

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on(
      "dialogAddRecruter",
      ({
        interviewId,
        existingRecruters,
        existingObservers,
        selectedCandidate,
        interviews,
      }) => {
        const FIRST_ELEMENT = 0;
        this.existingRecruters = existingRecruters;
        this.existingObservers = existingObservers;
        this.selectedCandidate = selectedCandidate;
        this.date = selectedCandidate.stages[FIRST_ELEMENT].date;
        this.time = selectedCandidate.stages[FIRST_ELEMENT].time;
        this.interviewId = interviewId;
        this.interviews = interviews;
        this.dialog = true;
      }
    );
  },

  computed: {
    filteredRecruters() {
      const existingRecruterIds = this.existingRecruters.map(
        (mapRecruter) => mapRecruter.user.id
      );

      const existingObserverIds = this.existingObservers.map(
        (mapRecruter) => mapRecruter.user.id
      );

      let resultItems = this.users;

      resultItems = resultItems.filter(
        (findRecruter) =>
          !existingRecruterIds.includes(findRecruter.id) &&
          !existingObserverIds.includes(findRecruter.id)
      );

      // resultItems = resultItems.filter(recruter => {
      //   const index = this.candidacies[0].guests.findIndex(guest => guest.id == recruter.id)
      //   return index === -1
      // })

      return resultItems;
    },
  },

  methods: {
    async addRecruter() {
      this.isLoading = true;
      const candidateIds = this.getCandidateIdsFromInterviews();
      const currentStage = this.getCurrentStage();

      try {
        await this.$apollo.mutate({
          mutation: ADD_RECRUTER_TO_INTERVIEW_MUTATION,
          variables: {
            interviewAddRecruterInput: {
              interviewId: this.interviewId,
              candidates: candidateIds,
              stage: currentStage,
              recruters: this.recruters.map((mapRecruter) => mapRecruter.id),
            },
          },
        });

        this.dialog = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee.id
          : mapInterview.email;
      });

      return candidates;
    },

    getCurrentStage() {
      const FIRST_ELEMENT = 0;
      const currentStage = this.interviews[FIRST_ELEMENT].currentStage;

      return currentStage;
    },

    cancel() {
      this.dialog = false;
    },
  },
};
</script>