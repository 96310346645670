<template>
  <v-dialog v-model="dialog" persistent max-width="350px">
    <v-card class="pa-10">
      <Transition name="fade" mode="out-in">
        <v-row v-if="isSelectCandidates" class="d-flex flex-column">
          <div class="text-center">
            {{ $t("Select_wish_complete_interview") }}
          </div>
          <div class="my-10">
            <candidate-combobox
              :candidacies="candidacies"
              @change="setCandidates"
            />
          </div>
          <div class="text-right">
            <v-btn text small color="primary" @click="dialog = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn small color="primary" @click="isSelectCandidates = false">
              {{ $t("continue") }}
            </v-btn>
          </div>
        </v-row>
        <v-row v-else class="d-flex flex-column">
          <div class="text-center">
            {{ $t("How_candidate_wait_feedback") }}
          </div>
          <div class="my-10 px-12">
            <v-text-field
              v-model.number="waitingDays"
              v-mask="'##'"
              dense
              outlined
              prepend-inner-icon="event"
              :label="$t('days')"
              hide-details
            >
            </v-text-field>
          </div>
          <div class="text-right">
            <v-btn
              text
              small
              color="primary"
              @click="isSelectCandidates = true"
            >
              {{ $t("back") }}
            </v-btn>
            <v-btn small color="primary" @click="finish()">
              {{ $t("finish") }}
            </v-btn>
          </div>
        </v-row>
      </Transition>
    </v-card>

    <progress-dialog :processing="isLoading" />
  </v-dialog>
</template>

<script>
import { UPDATE_STATUS_INTERVIEW_MUTATION } from "../graphql/Mutation.gql";
import CandidateCombobox from "./form/CandidateCombobox.vue";
import ProgressDialog from "../../../components/ProgressDialog.vue";
export default {
  name: "FinishInterview",

  components: {
    CandidateCombobox,
    ProgressDialog,
  },

  data: () => ({
    dialog: false,
    waitingDays: 0,
    isSelectCandidates: true,
    interviewId: null,
    status: "finished",
    candidacies: [],
    candidates: [],
    isLoading: false,
  }),

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("FinishInterview", ({ interviewId, interviews }) => {
      this.interviewId = interviewId;
      this.candidacies = interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee
          : { fullName: mapInterview.email };
      });
      this.isSelectCandidates = true;
      this.dialog = true;
    });
  },

  computed: {
    days() {
      const items = [];
      for (let index = 1; index <= 30; index++) {
        if (index === 1) {
          items.push({ key: `${index} ${this.$t("day")}`, value: index });
        } else {
          items.push({ key: `${index} ${this.$t("days")}`, value: index });
        }
      }

      return items;
    },
  },

  methods: {
    async finish() {
      this.isLoading = true;
      const candidates = this.getCandidateIdsFromInterviews();
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_STATUS_INTERVIEW_MUTATION,
          variables: {
            interviewUpdateStatusInput: {
              interviewId: this.interviewId,
              candidates,
              status: this.status,
              waitingDays: this.waitingDays,
            },
          },
        });
        this.dialog = false;
        const successMsg = this.$t("interview_ended_successfully");
        // eslint-disable-next-line no-undef
        Fire.$emit("SuccessDialog", successMsg);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.candidates.map((mapCandidate) => {
        return mapCandidate.id ? mapCandidate.id : mapCandidate.fullName;
      });

      return candidates;
    },

    setCandidates(candidates) {
      this.candidates = candidates;
    },
  },
};
</script>