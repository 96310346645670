<template>
  <v-dialog v-model="dialog" persistent max-width="700px">
    <v-card class="pa-10">
      <v-row class="d-flex flex-column">
        <div class="text-center">
          {{
            $t("send_personalized_email_reselected", {
              candidate: candidateName,
            })
          }}
        </div>
        <div class="mt-10">
          <tiptap-vuetify
            v-model="cancellationReason"
            :extensions="extensions"
            placeholder
            class="mb-2"
            style="max-width: 100%"
          />
        </div>
        <div class="text-right">
          <v-btn text small color="primary" @click="dialog = false">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small color="primary" @click="reSelect()">
            {{ $t("send") }}
          </v-btn>
        </div>
      </v-row>
    </v-card>

    <progress-dialog :processing="isLoading" />
  </v-dialog>
</template>

<script>
const { format } = require("date-fns");
const { pt: ptPT, enUS } = require("date-fns/locale");
import { UPDATE_STATUS_INTERVIEW_MUTATION } from "../graphql/Mutation.gql";
import ProgressDialog from "../../../components/ProgressDialog.vue";
import {
  TiptapVuetify,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
export default {
  name: "ReSelectCandidateDialog",

  components: {
    TiptapVuetify,
    ProgressDialog,
  },

  data: () => ({
    dialog: false,
    status: "approved",
    cancellationReason: "",
    interviewId: null,
    candidate: null,
    isLoading: false,
    extensions: [
      History,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
  }),

  created() {
    // eslint-disable-next-line no-undef
    Fire.$on(
      "reSelectCandidateDialod",
      ({ interviewId, selectedCandidate }) => {
        this.interviewId = interviewId;
        this.candidate = selectedCandidate;
        const currentStage = selectedCandidate.stages.find(
          (findStage) => findStage.name === selectedCandidate.currentStage
        );
        const { date, time } = currentStage;
        this.cancellationReason = this.$t("send_re_selectected_email", {
          candidate: this.candidateFullName,
          vacancy: this.candidate.vacancy,
          entity: this.candidate.entity,
          dateTime: this.localDate(`${date} ${time}`),
        });
        this.dialog = true;
      }
    );
  },

  computed: {
    candidateName() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.fullName
        : this.candidate.email;
    },
    candidateFullName() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.fullName
        : this.$i18n.locale === "en"
        ? "Candidate"
        : "Candidato";
    },
    candidateIdOrEmail() {
      return this.candidate.interviewee
        ? this.candidate.interviewee.id
        : this.candidate.email;
    },
  },

  methods: {
    async reSelect() {
      this.isLoading = true;
      try {
        await this.$apollo.mutate({
          mutation: UPDATE_STATUS_INTERVIEW_MUTATION,
          variables: {
            interviewUpdateStatusInput: {
              interviewId: this.interviewId,
              candidates: [this.candidateIdOrEmail],
              status: this.status,
              cancellationReason: this.cancellationReason,
            },
          },
        });
        this.dialog = false;
        const successMsg = this.$t("candidate_re_selected_successfully");
        // eslint-disable-next-line no-undef
        Fire.$emit("SuccessDialog", successMsg);
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },

    localDate(dateTime) {
      const currentLang = this.$i18n.locale;

      let formattedDate;
      switch (currentLang) {
        case "en":
          formattedDate = format(new Date(dateTime), "PPPPpp", {
            locale: enUS,
          });
          break;

        default:
          formattedDate = format(new Date(dateTime), "PPPPpp", {
            locale: ptPT,
          });
          break;
      }

      return formattedDate;
    },
  },
};
</script>