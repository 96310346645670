<template>
  <div>
    <div v-if="isCompleted" class="d-flex flex-column mb-12">
      <div class="title">{{ $t("what_was_assessed") }}</div>
    </div>
    <div v-else-if="isInterviewer" class="d-flex flex-column">
      <div class="title">{{ $t("what_you_evaluate") }}</div>
      <div
        class="text--secondary caption mb-1"
        v-html="$t('for_each_skill')"
      ></div>
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        @submit.prevent="addClassification()"
      >
        <v-row>
          <v-col cols="12" md="11" class="pr-0 mr-0">
            <div class="d-flex flex-row justify-sapce-between">
              <div style="width: 80%">
                <v-text-field
                  :label="$t('inserts_points_evaluate')"
                  placeholder="Placeholder"
                  outlined
                  dense
                  v-model="classification.designation"
                ></v-text-field>
              </div>
              <div class="mx-2" style="width: 20%">
                <v-text-field
                  :label="$t('weight')"
                  placeholder="Placeholder"
                  outlined
                  dense
                  :rules="requiredRules"
                  v-model.number="classification.percentageWeight"
                ></v-text-field>
              </div>
            </div>
          </v-col>
          <v-col cols="12" md="1" class="pl-0 ml-0">
            <v-btn fab dark outlined small color="primary" type="submit">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>

    <div class="d-flex flex-column justify-space-between">
      <template
        v-for="(classification, index) in selectedRecruter.classification"
      >
        <v-row :key="index">
          <v-col cols="12" md="10">
            <div class="d-flex flex-row justify-space-between">
              <div class="text--secondary" style="width: 80%">
                {{ index + 1 }}. {{ classification.designation }}
              </div>
              <div class="primary--text text-right" style="width: 20%">
                {{ classification.percentageWeight }}%
              </div>
            </div>
            <div class="mt-5">
              <v-slider
                v-model="classification.points"
                :label="$t('level_of_satisfaction')"
                step="1"
                thumb-label="always"
                :thumb-color="thumbColor(classification.points)"
                :thumb-size="22"
                @change="updateRecruterClassification(classification)"
                :readonly="readOnlyClassification"
              ></v-slider>
            </div>
          </v-col>
          <v-col v-if="!isCompleted" cols="12" md="2">
            <div v-if="classification.createdBy === userId">
              <v-btn
                icon
                x-small
                @click="setClassificationToEdit(classification)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                icon
                x-small
                @click="setClassificationToDelete(classification)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </template>
    </div>

    <v-dialog v-model="classificationDialog" persistent max-width="700px">
      <v-card class="pa-5">
        <v-container>
          <v-row
            class="d-flex flex-row justify-space-between align-center mb-1"
          >
            <div style="width: 85%">
              <v-text-field
                :label="$t('inserts_points_evaluate')"
                placeholder="Placeholder"
                outlined
                dense
                hide-details
                v-model="classification.designation"
              ></v-text-field>
            </div>
            <div style="width: 14%">
              <v-text-field
                label="Pontuação"
                placeholder="Placeholder"
                outlined
                dense
                hide-details
                v-model.number="classification.percentageWeight"
              ></v-text-field>
            </div>
          </v-row>
          <v-row class="d-flex flex-row justify-end align-center">
            <v-btn
              text
              small
              class="mr-2"
              @click="cancelUpdateClassification()"
            >
              {{ $t("cancel") }}
            </v-btn>
            <v-btn dark small color="primary" @click="updateClassification()">
              {{ $t("save") }}
            </v-btn>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <confirm-dialog
      :dialogConfirm="dialogConfirm"
      @yes="deleteClassification"
      @no="dialogConfirm.dialog = false"
    />
  </div>
</template>

<script>
import { API_URL } from "@/api";
import {
  ADD_INTERVIEW_CLASSIFICATION_MUTATION,
  UPDATE_RECRUTER_CLASSIFICATION_MUTATION,
  UPDATE_INTERVIEW_CLASSIFICATION_MUTATION,
  REMOVE_INTERVIEW_CLASSIFICATION_MUTATION,
} from "../../../graphql/Mutation.gql";
import ConfirmDialog from "../../ConfirmDialog.vue";
export default {
  name: "Classification",

  components: {
    ConfirmDialog,
  },

  props: {
    interviewId: { type: String },
    interviews: { type: Array },
    selectedCandidate: { type: Object },
    selectedRecruter: { type: Object },
    isCompleted: { type: Boolean },
    isRunning: { type: Boolean },
    isInterviewer: { type: Boolean },
    isObserver: { type: Boolean },
  },

  data: () => ({
    apiUrl: API_URL,
    dialogConfirm: {
      dialog: false,
      question: "",
      id: null,
    },
    value: 0,
    classification: {
      designation: "",
      points: 0,
      percentageWeight: null,
      createdBy: null,
    },
    emptyClassification: {
      designation: "",
      points: 0,
      percentageWeight: null,
      createdBy: null,
    },
    classifications: [],
    classificationDialog: false,
    valid: true,
  }),

  created() {},

  computed: {
    userId() {
      return this.$root.$data.userId;
    },

    readOnlyClassification() {
      return (
        this.selectedRecruter.user.id !== this.userId ||
        this.isCompleted ||
        !this.isRunning
      );
    },

    requiredRules() {
      return [(v) => !!v || this.$t("form_user_valid")];
    },
  },

  methods: {
    thumbColor(points) {
      if (points < 40) return "red";
      if (points < 50) return "orange";
      if (points < 75) return "indigo";
      if (points < 100) return "teal";
      return "green";
    },

    async updateRecruterClassification(classification) {
      try {
        const candidateIds = this.selectedCandidate.interviewee
          ? this.selectedCandidate.interviewee.id
          : this.selectedCandidate.email;

        const currentStage = this.getCurrentStage();

        await this.$apollo.mutate({
          mutation: UPDATE_RECRUTER_CLASSIFICATION_MUTATION,
          variables: {
            interviewRecruterClassificationInput: {
              interviewId: this.interviewId,
              candidateIdOrEmail: candidateIds,
              stage: currentStage,
              recruterId: this.selectedRecruter.user.id,
              classificationId: classification.id,
              points: classification.points,
              isObserver: this.selectedRecruter.isObserver,
            },
          },
        });
      } catch (error) {
        console.log(error);
      }
    },

    async addClassification() {
      if (this.$refs.form.validate()) {
        try {
          const candidateIds = this.getCandidateIdsFromInterviews();
          const currentStage = this.getCurrentStage();
          this.classification.createdBy = this.userId;

          await this.$apollo.mutate({
            mutation: ADD_INTERVIEW_CLASSIFICATION_MUTATION,
            variables: {
              interviewClassificationInput: {
                interviewId: this.interviewId,
                candidates: candidateIds,
                stage: currentStage,
                classification: this.classification,
              },
            },
          });
          this.classification = this.emptyClassification;
          this.classification.designation = "";
          this.classification.percentageWeight = null;
        } catch (error) {
          console.log(error);
        }
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee.id
          : mapInterview.email;
      });

      return candidates;
    },

    getCurrentStage() {
      const FIRST_ELEMENT = 0;
      const currentStage = this.interviews[FIRST_ELEMENT].currentStage;

      return currentStage;
    },

    setClassificationToEdit(classification) {
      this.classification = classification;
      this.classificationDialog = true;
    },

    setClassificationToDelete(classification) {
      this.dialogConfirm.id = classification.id;
      this.dialogConfirm.question = this.$t(
        "interview_classification_remove_question"
      );
      this.dialogConfirm.dialog = true;
    },

    async updateClassification() {
      try {
        const candidateIds = this.getCandidateIdsFromInterviews();
        const currentStage = this.getCurrentStage();

        await this.$apollo.mutate({
          mutation: UPDATE_INTERVIEW_CLASSIFICATION_MUTATION,
          variables: {
            interviewClassificationInput: {
              interviewId: this.interviewId,
              candidates: candidateIds,
              stage: currentStage,
              classificationId: this.classification.id,
              classification: this.classification,
            },
          },
        });
        this.classification = this.emptyClassification;
        this.classificationDialog = false;
      } catch (error) {
        console.log(error);
      }
    },

    cancelUpdateClassification() {
      this.classification = this.emptyClassification;
      this.classificationDialog = false;
    },

    async deleteClassification(classificationId) {
      try {
        const candidateIds = this.getCandidateIdsFromInterviews();
        const currentStage = this.getCurrentStage();

        await this.$apollo.mutate({
          mutation: REMOVE_INTERVIEW_CLASSIFICATION_MUTATION,
          variables: {
            interviewClassificationInput: {
              interviewId: this.interviewId,
              candidates: candidateIds,
              stage: currentStage,
              classificationId,
            },
          },
        });
        this.dialogConfirm.dialog = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>